<template>
  <v-container>
    <v-row>
      <v-col cols="12">
        <v-text-field
          placeholder="Search"
          :disabled="isLoading"
          outlined
          dense
          prepend-inner-icon="mdi-magnify"
          v-model="searchStr"
          @keyup.enter.native="fetchShops"
          class="float-right"
        >
        </v-text-field>
      </v-col>
    </v-row>
    <v-data-table
      :headers="headers"
      :items="shops"
      :loading="isLoading"
      sort-by="id"
      class="elevation-1"
      loading-text="Loading... Please wait"
      hide-default-footer
      :items-per-page="25"
    >
      <template v-slot:top>
        <v-toolbar flat>
          <v-toolbar-title>Shops</v-toolbar-title>
          <v-divider class="mx-4" inset vertical></v-divider>
        </v-toolbar>
      </template>
      <template v-slot:item.platform="{ item }">
        <div v-if="item.platform == 'shopify'">
            <v-img max-height="25" max-width="25" src="@/assets/images/shopify_logo.png"></v-img>  
        </div>
        <div v-if="item.platform == 'woocommerce'">
            <v-img max-height="25" max-width="25" src="@/assets/images/woo_logo.png"></v-img>  
        </div>
      </template>
      <template v-slot:item.store_type="{ item }">
          <v-badge
            color="primary"
            v-if="item.store_type != 'source'"
            content="D"
          ></v-badge>
          <v-badge 
            color="secondary"
            v-else
            content="S"
          ></v-badge>
      </template>
      <template v-slot:item.actions="{ item }">
        <v-icon small class="mr-2" @click="editShop(item)">
          mdi-eye
        </v-icon>
      </template>
    </v-data-table>
    <br /><br />
    <Pagination
      store="shops"
      collection="shops"
      :search-str="searchStr"
    ></Pagination>
  </v-container>
</template>

<script>
import { FETCH_SHOPS } from "@/store/actions.type";
import { mapGetters } from "vuex";
import Pagination from "@/views/components/Pagination";

export default {
  name: "StoresListingPage",
  components: {
    Pagination,
  },
  data: function() {
    return {
      loading: false,
      searchStr: "",
      headers: [
        { text: "ID", align: "start", value: "id" },
        { text: "Platform", value: "platform", sortable: false },
        { text: "Store Name", value: "store_domain", sortable: false },
        { text: "Type", value: "store_type", sortable: false },
        { text: "Connection Key", value: "identifier", sortable: false },
        { text: "Store Contact", value: "email", sortable: false },
        { text: "Installed At", value: "created_at", sortable: false },
        { text: "Connections", value: "connection_count", sortable: false },
        { text: "Actions", value: "actions", sortable: false },
      ],
    };
  },
  created() {
    let pageNum = 1;
    if (typeof (this.$route.params.page) != "undefined") {
      pageNum = this.$route.params.page;
    }
    this.fetchShops(pageNum);
  },
  computed: {
    ...mapGetters("shops", ["shops", "isLoading"]),
  },
  methods: {
    fetchShops(pageNum) {
      this.$store.dispatch(`shops/${FETCH_SHOPS}`, {
        pageNumber: pageNum,
        searchStr: this.searchStr,
      });
    },
    editShop(shop) {
      this.$router.push({ name: "SingleStorePage", params: { id: shop.id } });
    },
  },
};
</script>

<style lang="scss"></style>
